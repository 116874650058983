<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import type { ApiDestination } from '~/server/types/home'

type LocalizedUrl = Record<Locale, string>
const props = withDefaults(
  defineProps<{
    destinations: Array<
      ApiDestination & { searchUrl: LocalizedUrl; housesCount: number }
    >
    page: string
    destiId?: string | null
  }>(),
  {
    destiId: null,
  },
)

const { locale, t } = useI18n()
const localePath = useLocalePath()
const filteredDestinations = computed(() => {
  if (props.destiId) {
    return props.destinations.filter(
      (desti) => desti.id !== props.destiId && desti.searchUrl.en,
    )
  } else {
    return props.destinations.filter((desti) => desti.searchUrl.en)
  }
})

const getDestinationType = (url: Record<'en' | 'fr', string>) => {
  return url[locale.value as Locale].split('/')[0].split('-')[1]
}
const getAlt = (name: string, searchUrl: Record<'en' | 'fr', string>) =>
  t('global.destinations.altImage', {
    destinationName: name,
    houseType: getDestinationType(searchUrl),
  })
const getHref = (searchUrl: Record<'en' | 'fr', string>) => {
  const slugArr = searchUrl[locale.value as Locale].split('/')
  const name = slugArr[slugArr.length - 1]
  const destination = slugArr[0]

  return localePath({
    name: 'destination-name',
    params: {
      name,
      destination,
    },
  })
}
</script>

<template>
  <base-row v-if="filteredDestinations.length">
    <base-col
      v-for="destination in filteredDestinations"
      :key="destination.id"
      cols="24"
      sm="12"
      lg="8"
    >
      <nuxt-link
        class="text-gray-700 hover:text-gray-700 hover:no-underline"
        :to="getHref(destination.searchUrl)"
      >
        <base-media-object class="mb-12" direction="flex-col">
          <template #image>
            <div class="img-scale">
              <BaseNuxtImg
                :alt="getAlt(destination.name, destination.searchUrl)"
                :src="destination.coverPhotoUrl"
                height="269"
                loading="lazy"
                width="404"
              />
            </div>
          </template>
          <template #content>
            <div>
              <h3 class="mb-0 ml-2 mt-4 block text-2xl">
                {{ destination.name }}
              </h3>
              <span class="ml-2">{{
                $t(
                  `global.destinations.${getDestinationType(
                    destination.searchUrl,
                  )}`,
                  destination.housesCount,
                )
              }}</span>
            </div>
          </template>
        </base-media-object>
      </nuxt-link>
    </base-col>
  </base-row>
</template>

<style>
.img-scale {
  overflow: hidden;
}
.img-scale img {
  transform: scale(1.01);
  transition: transform ease-out 350ms;
}

.img-scale:hover img {
  transform: scale(1.1);
}
</style>

<script setup lang="ts">
type Service = {
  alt: string
  img: string
  label: string
  title: string
  video: string
}

defineProps<{
  service: Service
}>()

const { isSmallScreen, isMobile } = useBreakpoint()

const showImg = ref(true)

const serviceImageHeight = computed(() => (isMobile.value ? 493 : 587))
</script>

<template>
  <div
    :class="`
      h-[493px] md:h-[587px] bg-gray-100 block relative overflow-hidden
      after:content-[''] after:absolute after:bottom-0 after:w-full after:h-1/2
      after:bg-gradient-to-t after:from-[rgba(0,0,0,0.6)] after:to-transparent
    `"
    @mouseover="showImg = false"
    @mouseleave="showImg = true"
  >
    <template v-if="isSmallScreen">
      <BaseNuxtImg
        :alt="service.alt"
        :height="serviceImageHeight"
        :src="service.img"
        loading="lazy"
        width="400"
      />
    </template>
    <template v-else>
      <BaseNuxtImg
        v-show="showImg"
        :alt="service.alt"
        :height="serviceImageHeight"
        :src="service.img"
        loading="lazy"
        width="400"
      />
      <BaseNuxtImg
        v-show="!showImg"
        :alt="service.alt"
        :height="serviceImageHeight"
        :src="service.video"
        loading="lazy"
        width="400"
      />
    </template>
    <div
      class="absolute bottom-0 left-0 z-[2] flex h-[76%] w-full flex-col justify-end p-6 text-white duration-500"
    >
      <p class="mb-2 text-base">
        {{ service.label }}
      </p>
      <h3 class="pr-8 text-4xl text-white">
        {{ service.title }}
      </h3>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const values = ref([
  {
    iconName: 'lcVerifiedBadge',
    id: 'value-badge',
    title: t('global.values.value1.title'),
    text: t('global.values.value1.text'),
  },
  {
    iconName: 'clock',
    id: 'value-clock',
    title: t('global.values.value2.title'),
    text: t('global.values.value2.text'),
  },
  {
    iconName: 'lock',
    id: 'value-lock',
    title: t('global.values.value3.title'),
    text: t('global.values.value3.text'),
  },
  {
    iconName: 'shield',
    id: 'value-shield',
    title: t('global.values.value4.title'),
    text: t('global.values.value4.text'),
  },
] as const)
</script>

<template>
  <div class="values">
    <base-container>
      <base-row>
        <base-col
          v-for="value in values"
          :key="value.id"
          class="values__blocks"
          cols="12"
          lg="6"
        >
          <BaseIcon
            :name="value.iconName"
            :size="3"
            class="mx-auto mb-4 stroke-none md:mb-6"
            filled
          />

          <p class="values__title text-font-family-serif">
            {{ value.title }}
          </p>
          <p class="values__text">
            {{ value.text }}
          </p>
        </base-col>
      </base-row>
    </base-container>
  </div>
</template>

<style>
.values {
  @apply bg-gray-100 text-center;
  padding: 64px 0;
}

.values__blocks:nth-child(1n + 3) {
  margin: 2rem 0 0 0;
}

@screen lg {
  .values__blocks:nth-child(1n + 3) {
    @apply m-0;
  }
}

.values__title {
  @apply font-medium text-xl m-0;
}

@screen md {
  .values__title {
    margin: 0 0 0.5rem 0;
  }
}

.values__text {
  @apply hidden;
}

@screen md {
  .values__text {
    @apply inline-block;
  }
}
</style>

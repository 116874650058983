<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { locale } = useI18n()
const medias = ref({
  fr: {
    media1: {
      id: 'IDEAT',
      image: '/production/assets/images/press-articles/logo-fr/ideat.png',
      url: 'https://ideat.fr/maison-architecte-afrique-du-sud-louer/',
    },
    media2: {
      id: 'AD',
      image: '/production/assets/images/press-articles/logo-fr/ad.png',
      url: 'https://www.admagazine.fr/article/maisons-vacances-paques-marrakech-toscane',
    },
    media3: {
      id: 'L’Officiel FR',
      image:
        '/production/assets/images/press-articles/logo-fr/lofficiel-fr.png',
      url: 'https://www.lofficiel.com/voyage/paques-2023-nos-5-idees-d-escapades-a-moins-de-2-heures-de-paris',
    },
    media4: {
      id: 'Elle',
      image: '/production/assets/images/press-articles/logo-fr/elle.png',
      url: 'https://www.elle.fr/Loisirs/Evasion/Et-si-on-repartait-en-vacances-Nos-idees-d-escapades-4174825',
    },
    media5: {
      id: 'Challenges',
      image: '/production/assets/images/press-articles/logo-fr/challenges.png',
      url: 'https://www.challenges.fr/luxe/city-guide-les-adresses-incontournables-du-cap-ferret_872316',
    },
  },
  en: {
    media1: {
      id: 'FT',
      image:
        '/production/assets/images/press-articles/logo-en/financial-times.png',
      url: 'https://www.ft.com/content/d094e0e8-799b-4639-acfd-b0cf5ec88109',
    },
    media2: {
      id: 'Vanity Fair',
      image: '/production/assets/images/press-articles/logo-en/vanity-fair.png',
      url: 'https://www.revistavanityfair.es/articulos/le-collectionist-alquiler-casas-de-lujo',
    },
    media3: {
      id: 'Bloomberg',
      image: '/production/assets/images/press-articles/logo-en/bloomberg.png',
      url: 'https://www.bloomberg.com/news/features/2023-08-16/12-wild-things-i-learned-as-an-ibiza-nightlife-concierge-to-ultra-vips',
    },
    media4: {
      id: 'The Times',
      image: '/production/assets/images/press-articles/logo-en/the-times.png',
      url: 'https://www.thetimes.co.uk/article/ive-found-the-secret-to-unlocking-the-grown-up-side-of-ibiza-7bt7t8nxg',
    },
    media5: {
      id: 'AD',
      image: '/production/assets/images/press-articles/logo-en/ad.png',
      url: 'https://www.ad-magazin.de/artikel/le-collectionist-traumvilla-hyeres',
    },
  },
})
</script>

<template>
  <base-container>
    <base-row>
      <base-col class="medias-block">
        <a
          v-for="media in medias[locale as Locale]"
          :key="media.id"
          :aria-label="media.id"
          :href="media.url"
          class="medias-block__media"
          rel="noopener nofollow"
          target="_blank"
        >
          <BaseNuxtImg
            :alt="`Logo ${media.id}`"
            :src="media.image"
            fit="contain"
            height="40"
            loading="lazy"
            width="140"
          />
        </a>
      </base-col>
    </base-row>
  </base-container>
</template>

<style>
.medias-block {
  @apply flex flex-wrap justify-center;
  margin: 0 0 4rem 0;
}

@screen xl {
  .medias-block {
    @apply justify-between;
  }
}

.medias-block__media {
  padding: 24px 16px;
}
</style>
